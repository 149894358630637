import { FC } from 'react'
import { Vehicle } from '@/types/Vehicle'
import { Heading, Text } from '@/components/ui'
import { ProductPurchaseButton } from './ProductPurchaseButton'

interface ProductSidebarProps {
  product: Vehicle
}

export const ProductSidebar: FC<ProductSidebarProps> = ({ product }) => {
  return (
    <div className="col-span-4 xl:col-span-1 flex flex-col space-y-10 bg-neutral-100 dark:bg-neutral-800 border border-neutral-200 dark:border-neutral-700 rounded-xl p-7">
      <div className="flex flex-col space-y-2 text-center">
        <Text size="lg">{product.brand}</Text>
        <Heading level="3">{product.model}</Heading>
      </div>

      <div className="flex flex-col space-y-2.5">
        <ProductPurchaseButton
          color="secondary"
          productId={product.tebexPackageId.toString()}
        >
          Buy ${product.price}
        </ProductPurchaseButton>

        <ProductPurchaseButton productId="5756250">
          All Vehicles $24.99/mo.
        </ProductPurchaseButton>
      </div>

      <div className="border-t border-neutral-200 dark:border-neutral-700" />

      <div>
        <Text weight="bold" color="primary">
          Specifications
        </Text>

        <div className="grid grid-cols-2">
          <Text>Polygons</Text>
          <Text className="text-right">
            {product.specifications.polygons.toLocaleString('en-US')}
          </Text>
        </div>
        <div className="grid grid-cols-2">
          <Text>Vertices</Text>
          <Text className="text-right">
            {product.specifications.vertices.toLocaleString('en-US')}
          </Text>
        </div>
        <div className="grid grid-cols-2">
          <Text>YFT Size</Text>
          <Text className="text-right">
            {`${product.specifications.yftSize} MB`}
          </Text>
        </div>
        <div className="grid grid-cols-2">
          <Text>YTD Size</Text>
          <Text className="text-right">
            {`${product.specifications.ytdSize} MB`}
          </Text>
        </div>
      </div>
    </div>
  )
}
