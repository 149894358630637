import { Heading, Text, Card } from '@/components/ui'

const vehicleBrands = [
  {
    title: '4Rings'
  },
  {
    title: 'Bayer'
  },
  {
    title: 'BlueOval'
  },
  {
    title: 'Liquid'
  },
  {
    title: 'MB'
  },
  {
    title: 'Elon'
  }
]

export const VehicleSection = () => (
  <>
    <Heading level="2">Vehicles</Heading>
    <Text size="lg" className="mt-4">
      Every vehicle is driveable as a high-resolution 3D vehicle in FiveM with
      all associated functions. This includes working mirrors, speedometer,
      doors, breakable glass, accurate lighting and correct steering wheel
      positioning and much more.
    </Text>

    <div className="grid grid-cols-1 mt-12 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-12">
      {vehicleBrands.map(vehicleBrand => (
        <Card
          key={vehicleBrand.title}
          title={vehicleBrand.title}
          description={`Explore the many choices in ${vehicleBrand.title}'s
                lineup of electric vehicles for FiveM and GTA.`}
          price="29.99 per vehicle"
          image={`/assets/vehicles/${vehicleBrand.title.toLowerCase()}/all-vehicles.jpg`}
          link={`/vehicles/${vehicleBrand.title.toLowerCase()}`}
        />
      ))}
    </div>
  </>
)
