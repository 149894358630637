import { Divider } from '@/components/ui'
import { Layout } from '@/components/common'
import { FC } from 'react'
import { ResourceSection } from './fragments/ResourceSection'
import { VehicleSection } from './fragments/VehicleSection'
import { TestimonialSection } from './fragments/TestimonialSection'
import { HeroSection } from './fragments/HeroSection'

export const Home: FC = () => (
  <Layout>
    <HeroSection />
    <Divider />
    <ResourceSection />
    <Divider />
    <VehicleSection />
    <Divider />
    <TestimonialSection />
  </Layout>
)
