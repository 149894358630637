import { Vehicle } from '@/types/Vehicle'
import { FC } from 'react'
import { Heading, Text } from '../ui'

interface ProductDescriptionProps {
  product: Vehicle
}

export const ProductDescription: FC<ProductDescriptionProps> = ({
  product
}) => {
  return (
    <>
      <Heading level="2">Description</Heading>
      <Text size="lg" className="mt-4">
        {product.description}
      </Text>
    </>
  )
}
