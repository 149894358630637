import { FC } from 'react'
import {
  Divider,
  Heading,
  Text,
  DisclosureItemProps,
  DisclosureItem
} from '@/components/ui'
import { Layout } from '@/components/common'
import { ProductPurchaseButton } from '../product'
import { HiCheck } from 'react-icons/hi2'

const faqItems: DisclosureItemProps[] = [
  {
    question: 'What is included the Resources & MLOs subscription?',
    answer:
      "Our Resources & MLOs subscription provides you with instant access to a comprehensive range of resources and MLOs designed to support your projects and endeavors. For a monthly fee of $14.99, you gain unlimited access to our extensive library of resources and MLOs, empowering you to enhance your work efficiency and unlock new possibilities. With easy installation through drag & drop and the ability to propose individual features, our subscription puts valuable tools at your fingertips. Additionally, you'll enjoy community support and receive monthly updates, ensuring you stay up to date with the latest advancements."
  },
  {
    question: 'What is included in the Vehicles subscription?',
    answer:
      'The Vehicles subscription grants you instant access to an impressive selection of vehicles tailored to your desires. For just $24.99 per month, you can experience the thrill and convenience of driving without the burdens of ownership. With our subscription, you gain access to all our vehicles, allowing you to choose the perfect ride for any occasion. Whether you need a compact car for urban commutes, a spacious SUV for family trips, or a luxurious sedan for a special event, our Vehicles subscription has you covered. You also have the opportunity to propose individual features, ensuring that your driving experience is personalized to your preferences. With easy installation through drag & drop, community support, and monthly updates, our Vehicles subscription provides the freedom and flexibility you seek.'
  },
  {
    question: 'Can I subscribe to both Resources & MLOs and Vehicles?',
    answer:
      "Absolutely! We understand that your interests and needs may extend beyond a single subscription. You have the flexibility to subscribe to both our Resources & MLOs and Vehicles packages, gaining full access to our extensive resource library and diverse fleet of vehicles. By subscribing to both, you'll have unlimited opportunities for growth, exploration, and convenience. Maximize your experience with our comprehensive subscriptions and unleash the full potential of our offerings."
  },
  {
    question: 'How can I sign up for a subscription?',
    answer:
      'Signing up for a subscription is quick and easy! Simply visit our website and navigate to the subscription page. From there, you can choose the subscription(s) that align with your interests. Follow the prompts to create an account, select your preferred payment method, and complete the subscription process. Once done, you will gain instant access to all the resources and vehicles included in your chosen subscription(s). Welcome to a world of possibilities and convenience!'
  },
  {
    question: 'What if I have additional questions or need support?',
    answer:
      'We are here to assist you every step of the way. If you have any additional questions or require support regarding our subscriptions, our friendly customer support team is available to help. You can reach out to us through our Discord or utilize the community support feature included in your subscription. We are dedicated to ensuring your subscription experience is seamless and satisfactory.'
  },
  {
    question:
      'Are there any limitations on the number of resources or vehicles I can access?',
    answer:
      'No, there are no limitations on the number of resources, mlos or vehicles you can access with your subscription. Once you become a subscriber, you gain unlimited access to our entire resource library and the full range of vehicles we offer. Enjoy the freedom to explore and utilize as many resources or vehicles as you need to accomplish your goals and enhance your experiences.'
  },
  {
    question:
      'Are there any discounts or promotions available for subscriptions?',
    answer:
      "We occasionally run special promotions and offer discounts on our subscriptions. To stay updated on the latest discounts and promotions, we encourage you to join our Discord or follow us on social media. By doing so, you'll be among the first to know about any exciting offers that may become available."
  },
  {
    question: 'Can I share my subscription with others?',
    answer:
      'Your subscription is intended for individual use and is non-transferable. Sharing your subscription account with others is not permitted. Each subscription is tied to a single user account, and access is granted only to the account holder. If others are interested in accessing our resources, mlos or vehicles, we encourage them to sign up for their own subscription.'
  }
]

export const SubscriptionPage: FC = () => {
  return (
    <Layout>
      <div>
        <Heading level="1" className="text-center">
          Subscriptions
        </Heading>

        <div className="max-w-5xl mx-auto mt-10 lg:mt-12 text-center">
          <Text size="lg">
            With our subscription, you&apos;ll get instant access to all the
            resources, MLOs and vehicles we have to offer. With easy
            installation through drag & drop and community support, our
            subscriptions offer the ultimate convenience and value for your
            FiveM experience.
          </Text>
        </div>
      </div>

      <div className="grid grid-cols1 lg:grid-cols-2 gap-x-5 gap-y-5 pt-20 lg:pt-24">
        <div className="flex flex-col gap-y-5 h-full bg-neutral-100 dark:bg-neutral-800 rounded-xl border border-neutral-200 dark:border-neutral-700">
          <div className="text-center flex flex-col gap-y-5 pt-7 px-7">
            <div>
              <Heading visualLevel="3">Resources & MLOs</Heading>

              <Text className="pt-2.5">
                Get instant access to all the resources & MLOs we have to offer.
              </Text>
            </div>

            <div className="flex justify-center items-baseline">
              <Heading visualLevel="2">
                <span className="inline-block text-tesla-red">$14.99</span>
              </Heading>
              <Text>/month</Text>
            </div>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700" />

          <ul role="list" className="flex flex-col gap-2.5 px-7 text-left">
            <li className="flex items-center gap-x-3">
              <HiCheck size={24} className="text-tesla-red" />
              <Text>Instant access to all our resources and MLOs</Text>
            </li>
            <li className="flex items-center gap-x-3">
              <HiCheck size={24} className="text-tesla-red" />
              <Text>Possibility to propose individual features</Text>
            </li>
            <li className="flex items-center gap-x-3">
              <HiCheck size={24} className="text-tesla-red" />
              <Text>Easy installation through drag & drop</Text>
            </li>
            <li className="flex items-center gap-x-3">
              <HiCheck size={24} className="text-tesla-red" />
              <Text>Community support</Text>
            </li>
            <li className="flex items-center gap-x-3">
              <HiCheck size={24} className="text-tesla-red" />
              <Text>Monthly updates</Text>
            </li>
          </ul>

          <div className="flex flex-col px-7 pb-7">
            <ProductPurchaseButton productId="5756249">
              All Resources & MLOs $14.99/mo.
            </ProductPurchaseButton>
          </div>
        </div>

        <div className="flex flex-col gap-y-5 h-full bg-neutral-100 dark:bg-neutral-800 rounded-xl border border-neutral-200 dark:border-neutral-700">
          <div className="text-center flex flex-col gap-y-5 pt-7 px-7">
            <div>
              <Heading visualLevel="3">Vehicles</Heading>

              <Text className="pt-2.5">
                Get instant access to all the vehicles we have to offer.
              </Text>
            </div>

            <div className="flex justify-center items-baseline">
              <Heading visualLevel="2">
                <span className="inline-block text-tesla-red">$24.99</span>
              </Heading>
              <Text>/month</Text>
            </div>
          </div>

          <div className="border-b border-neutral-200 dark:border-neutral-700" />

          <ul role="list" className="flex flex-col gap-2.5 px-7 text-left">
            <li className="flex items-center gap-x-3">
              <HiCheck size={24} className="text-tesla-red" />
              <Text>Instant access to all our vehicles</Text>
            </li>
            <li className="flex items-center gap-x-3">
              <HiCheck size={24} className="text-tesla-red" />
              <Text>Possibility to propose individual features</Text>
            </li>
            <li className="flex items-center gap-x-3">
              <HiCheck size={24} className="text-tesla-red" />
              <Text>Easy installation through drag & drop</Text>
            </li>
            <li className="flex items-center gap-x-3">
              <HiCheck size={24} className="text-tesla-red" />
              <Text>Community support</Text>
            </li>
            <li className="flex items-center gap-x-3">
              <HiCheck size={24} className="text-tesla-red" />
              <Text>Monthly updates</Text>
            </li>
          </ul>

          <div className="flex flex-col px-7 pb-7">
            <ProductPurchaseButton productId="5756250">
              All Vehicles $24.99/mo.
            </ProductPurchaseButton>
          </div>
        </div>
      </div>

      <Divider />

      <Heading level="2">Questions? Answers.</Heading>
      <Text size="lg" className="mt-4">
        If you have any further questions or concerns regarding our
        subscriptions, feel free to reach out to our support team. We&apos;re
        dedicated to providing you with a seamless subscription experience and
        helping you make the most of our offerings.
      </Text>

      <div className="mt-12 flex flex-col space-y-7">
        {faqItems.map((item, index) => (
          <>
            <DisclosureItem
              key={index}
              question={item.question}
              answer={item.answer}
            />
            {index !== faqItems.length - 1 && (
              <hr className="border-neutral-200 dark:border-neutral-700" />
            )}
          </>
        ))}
      </div>
    </Layout>
  )
}
