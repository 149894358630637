import { FC } from 'react'
import { Breadcrumb, BreadcrumbItem, Divider } from '@/components/ui'
import { Layout, SEO } from '@/components/common'
import { Vehicle } from 'types'
import {
  ProductSidebar,
  ProductImageGrid,
  ProductFeatureGrid
} from '@/components/product'
import { ProductDescription } from './ProductDescription'

export interface ProductViewProps {
  product: Vehicle
}

export const ProductView: FC<ProductViewProps> = ({ product }) => {
  return (
    <>
      <Breadcrumb>
        <BreadcrumbItem href="/vehicles">Vehicles</BreadcrumbItem>
        <BreadcrumbItem href={`/vehicles/${product.brand.toLowerCase()}`}>
          {product.brand}
        </BreadcrumbItem>
        <BreadcrumbItem>{product.model}</BreadcrumbItem>
      </Breadcrumb>

      <Layout>
        <SEO product={product} />

        <div className="flex flex-col space-y-8">
          <div className="grid grid-cols-4 gap-8">
            <ProductImageGrid product={product} />
            <ProductSidebar product={product} />
          </div>
        </div>
        <Divider />
        <ProductDescription product={product} />
        <Divider />
        <ProductFeatureGrid />
      </Layout>
    </>
  )
}
