import { Card, Heading, Text } from '@/components/ui'
import { FC } from 'react'
import {
  GiCarDoor,
  GiCrackedGlass,
  GiSpeedometer,
  GiSteeringWheel,
  GiLightBulb,
  GiCarSeat,
  GiMirrorMirror,
  GiPlatform
} from 'react-icons/gi'

export const ProductFeatureGrid: FC = () => {
  interface VehicleFeature {
    title: string
    icon: JSX.Element
    description: string
  }

  const vehicleFeatures: VehicleFeature[] = [
    {
      title: '3D model',
      icon: <GiPlatform size={28} />,
      description: `Our vehicles are built on high quality 3D models based on the original vehicle dimensions.`
    },
    {
      title: 'Doors, frunk & trunk',
      icon: <GiCarDoor size={28} />,
      description: `All doors, frunk and trunk can be opened and closed and come with proper door sills and jams.`
    },
    {
      title: 'Interior',
      icon: <GiCarSeat size={28} />,
      description: `Vehicles come with high quality interior which is based on 3D objects not only textures.`
    },
    {
      title: 'Lights',
      icon: <GiLightBulb size={28} />,
      description: `Includes headlights, turn signals, taillights, brake lights, rear turn signals and interior lights.`
    },
    {
      title: 'Glasses',
      icon: <GiCrackedGlass size={28} />,
      description: `All glasses including lights can be broken and have a specific crack texture.`
    },
    {
      title: 'Tachometer',
      icon: <GiSpeedometer size={28} />,
      description: `The electronic tachometer works and shows the current speed and gear with precision.`
    },
    {
      title: 'Steering wheel',
      icon: <GiSteeringWheel size={28} />,
      description: `The player's hands are on the steering wheel, which rotates accordingly.`
    },
    {
      title: ' Mirrors',
      icon: <GiMirrorMirror size={28} />,
      description: `The mirrors are fully functional, and represent the corresponding viewing angle.`
    }
  ]

  return (
    <div>
      <Heading level="2">Features</Heading>
      <Text size="lg" className="mt-4">
        All our vehicles contain essential features that ensure the best
        possible quality and gaming experience. All vehicles are LOD optimized
        to ensure optimal performance of the highly detailed 3D models.
      </Text>

      <div className="grid grid-cols-1 mt-12 gap-6 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4">
        {vehicleFeatures.map(vehicleFeature => (
          <Card
            key={vehicleFeature.title}
            title={vehicleFeature.title}
            description={vehicleFeature.description}
            icon={vehicleFeature.icon}
          />
        ))}
      </div>
    </div>
  )
}
