import { BaseComponentProps } from '@/types/BaseComponent'
import { FC, ReactNode } from 'react'
import cn from 'clsx'

interface LayoutProps extends BaseComponentProps {
  children: ReactNode
}

export const Layout: FC<LayoutProps> = ({ children, className }) => (
  <div
    className={cn(
      className,
      'px-4 sm:px-6 py-20 lg:py-24 mx-auto lg:max-w-[90rem]'
    )}
  >
    {children}
  </div>
)
