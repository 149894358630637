import { FC } from 'react'
import { Button } from '../ui'
import { BaseComponentProps } from '@/types/BaseComponent'

interface ProductPurchaseButtonProps extends BaseComponentProps {
  productId: string
  color?: 'primary' | 'corporate' | 'secondary'
  isDisabled?: boolean
}

export const ProductPurchaseButton: FC<ProductPurchaseButtonProps> = ({
  productId,
  color = 'corporate',
  isDisabled = false,
  className,
  children
}) => {
  return (
    <Button
      href={`https://playlolly-store.tebex.io/package/${productId}`}
      color={color}
      isDisabled={isDisabled}
      className={className}
    >
      {children}
    </Button>
  )
}
