import Image from 'next/image'
import { FC, useState } from 'react'
import { Vehicle } from '@/types/Vehicle'
import { ImageCarousel } from '@/components/ui'

interface ProductImageGridProps {
  product: Vehicle
}

export const ProductImageGrid: FC<ProductImageGridProps> = ({ product }) => {
  const [isImageCarouselVisible, setIsImageCarouselVisible] = useState(false)
  const [imageCarouselIndex, setImageCarouselIndex] = useState(0)
  const images = [...Array(product.numberOfImages)].map((_, i) => ({
    src: `/assets/vehicles/${product.brand.toLowerCase()}/${
      product.tebexPackageId
    }/${i + 1}.jpg`,
    width: 1920,
    height: 1080,
    alt: `${product.brand} ${product.model}`
  }))

  return (
    <>
      <ImageCarousel
        images={images}
        index={imageCarouselIndex}
        isVisible={isImageCarouselVisible}
        setIsVisible={setIsImageCarouselVisible}
      />

      <div className="col-span-4 xl:col-span-3">
        <Image
          src={`/assets/vehicles/${product.brand.toLowerCase()}/${
            product.tebexPackageId
          }/1.jpg`}
          alt={`${product.brand} ${product.model}`}
          width={1920}
          height={1080}
          sizes="100vw"
          className="rounded-t-xl cursor-pointer"
          onClick={() => {
            setImageCarouselIndex(0)
            setIsImageCarouselVisible(true)
          }}
        />
        <div className="pt-2 grid grid-flow-col auto-cols-auto gap-x-2">
          {[...Array(product.numberOfImages - 1)].map((_, i) => (
            <Image
              src={`/assets/vehicles/${product.brand.toLowerCase()}/${
                product.tebexPackageId
              }/${i + 2}.jpg`}
              alt={`${product.brand} ${product.model}`}
              width={1920}
              height={1080}
              sizes="100vw"
              className={`${i === 0 ? 'rounded-bl-xl' : ''} ${
                i === product.numberOfImages - 2 ? 'rounded-br-xl' : ''
              } cursor-pointer`}
              key={i}
              onClick={() => {
                setImageCarouselIndex(i + 1)
                setIsImageCarouselVisible(true)
              }}
            />
          ))}
        </div>
      </div>
    </>
  )
}
