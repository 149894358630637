import { Heading, Text, Card } from '@/components/ui'

const resources = [
  {
    title: 'Drive',
    image: 'cable-charging',
    description: `A huge map with over 70 EV chargers that are true to detail and your vehicles can be charged at.`
  },
  {
    title: 'HUD',
    image: 'overview',
    description: `Visually displays all the information about your ride in a Head Up Display.`
  },
  {
    title: 'Autonomous',
    image: 'autopilot',
    description: `Use Autopilot, Crash Avoidance, Auto Lane Change, Smart Summon, Reverse Camera etc.`
  },
  {
    title: 'Dealership',
    image: 'dealer-light',
    description: `A vibrant dealership where you can view, test drive, rent, buy or configure all available vehicles.`
  }
]

export const ResourceSection = () => (
  <>
    <Heading level="2">Resources</Heading>
    <Text size="lg" className="mt-4">
      All possible resources to ensure the best possible and most faithful EV
      experience. With electric vehicle HUD, a distributed EV charging network,
      autonomous functions and a dealership with webshop.
    </Text>

    <div className="grid grid-cols-1 mt-12 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-12">
      {resources.map(resource => (
        <Card
          key={resource.title}
          title={resource.title}
          description={resource.description}
          price="39.99"
          image={`/assets/resources/${resource.title.toLowerCase()}/${
            resource.image
          }.jpg`}
          link={`/resources/${resource.title.toLowerCase()}`}
        />
      ))}
    </div>
  </>
)
