import Image from 'next/image'
import { Heading } from '@/components/ui'
import { FC } from 'react'

export const HeroSection: FC = () => (
  <>
    <Heading level="1" className="text-center">
      Powerful FiveM
      <br className="hidden lg:block" />
      <span className="inline-block text-tesla-red">&nbsp;EV resources</span>
    </Heading>

    <p className="mx-auto mt-6 font-medium leading-tight text-center text-neutral-500 dark:text-neutral-400 sm:max-w-5xl sm:text-2xl md:text-3xl lg:text-4xl">
      With our resources, you have everything you need for a realistic EV
      vehicle experience, right on your FiveM server.
    </p>

    <div className="pt-12">
      <div className="max-w-4xl px-4 mx-auto sm:px-6 lg:px-8">
        <p className="font-semibold tracking-wide text-center text-neutral-500 dark:text-neutral-400 uppercase">
          Flawless integration with existing FiveM frameworks
        </p>

        <div className="grid grid-cols-2 mt-6 max-w-xs mx-auto">
          <div>
            <Image
              className="contrast-0"
              src="/assets/logos/qbcore.png"
              alt="QBCore"
              width={87}
              height={30}
            />
          </div>
          <div>
            <Image
              className="contrast-0 float-right"
              src="/assets/logos/esx.png"
              alt="ESX"
              width={87}
              height={30}
            />
          </div>
        </div>
      </div>
    </div>
  </>
)
