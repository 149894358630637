import { FC } from 'react'
import { Vehicle } from '@/types/Vehicle'
import { ProductJsonLd } from 'next-seo'

interface SEOProps {
  product: Vehicle
}

export const SEO: FC<SEOProps> = ({ product }) => (
  <ProductJsonLd
    productName={`${product.brand} ${product.model}`}
    images={Array.from({ length: product.numberOfImages }, (_, i) => {
      return `https://playlolly.net/assets/vehicles/${product.brand.toLowerCase()}/${
        product.tebexPackageId
      }/${i + 1}.jpg`
    })}
    description={
      'For Grand Theft Auto 5 (GTA V) and FiveM.' + product.description
    }
    brand={product.brand}
    manufacturerName="Playlolly"
    manufacturerLogo="https://playlolly.net/assets/og-image.jpg"
    slogan="Powerful FiveM & GTA V EV resources"
    disambiguatingDescription={
      'For Grand Theft Auto 5 (GTA V) and FiveM.' + product.description
    }
    releaseDate="2022-10-01T00:00:00Z"
    reviews={[]}
    aggregateRating={{
      ratingValue: '5.0',
      reviewCount: product.reviewCount.toString() ?? '100'
    }}
    offers={[
      {
        price: `${product.price.toString()}`,
        priceCurrency: 'USD',
        priceValidUntil: '2023-12-12',
        itemCondition: 'https://schema.org/NewCondition',
        availability: 'https://schema.org/InStock',
        url: `https://playlolly.net/vehicles/${product.brand.toLowerCase()}/${product.model
          .toLowerCase()
          .replace(/ & /g, '-')
          .replace(/ /g, '-')}`,
        seller: {
          name: 'Playlolly'
        }
      }
    ]}
    sku={product.tebexPackageId.toString()}
    mpn={product.tebexPackageId.toString()}
  />
)
