import { FC } from 'react'
import { Heading, Review } from '@/components/ui'
import nephilim from 'public/assets/testimonials/nephilim.jpg'
import onurabicim from 'public/assets/testimonials/onurabicim.jpg'
import moebi from 'public/assets/testimonials/moebi.jpg'
import mrpickles from 'public/assets/testimonials/mrpickles.jpg'
import beauflexx from 'public/assets/testimonials/beauflexx.jpg'
import inchon from 'public/assets/testimonials/inchon.jpg'
import faiterlp from 'public/assets/testimonials/faiterlp.jpg'
import soletick from 'public/assets/testimonials/soletick.jpg'
import xesteral from 'public/assets/testimonials/xesteral.jpg'

export const TestimonialSection: FC = () => {
  const configFirst = [
    {
      name: 'Nephilim | Sebastian',
      date: 'Mar 6',
      avatar: nephilim,
      text: 'Very good EV scripts looking forward to more updates! ⚡️'
    },
    {
      name: 'Mr. Pickles',
      date: 'Mar 18',
      avatar: mrpickles,
      text: 'The support team has been great, its a great resource. I do encourage those who like what they see in the preview videos to purchase the product, its a great product.'
    },
    {
      name: 'Onurabicim',
      date: 'Mar 13',
      avatar: onurabicim,
      text: 'Nice EV scripts! My Community and I we love it! ❤️'
    }
  ]

  const configSecond = [
    {
      name: 'Möbi',
      date: 'Mar 11',
      avatar: moebi,
      text: 'Mega! Thanks for the super fast implementation on feature requests and the awesome support! Amazing scripts, really! 🤯'
    },
    {
      name: 'Inchon',
      date: 'Mar 14',
      avatar: inchon,
      text: 'It is all like being in a real EV, very impressive and so much fun to play with. 🕹️'
    },
    {
      name: 'Beauflexx',
      date: 'Mar 10',
      avatar: beauflexx,
      text: 'Dope, hahaha it can be pretty wild. Its a dope package, players are enjoying it! Thanks for the numerous updates. Good work guys. 🙏'
    }
  ]

  const configThird = [
    {
      name: 'FaiterLP',
      date: 'Apr 8',
      avatar: faiterlp,
      text: 'Very Nice Script. Works very well and the support is 12/10. 💫'
    },
    {
      name: 'Sole Tick',
      date: 'Mar 8',
      avatar: soletick,
      text: 'I requested a new feature, literally within a day there was an update including my requested feature. The team is incredibly fast and helpful. Can highly recommend this to everyone.'
    },
    {
      name: 'Xesteral',
      date: 'Mar 17',
      avatar: xesteral,
      text: 'Extremely simple installation, while everything is freely configurable. 🌟'
    }
  ]

  return (
    <>
      <Heading level="2" className="text-center max-w-4xl mx-auto">
        Loved by server owners and players&nbsp;
        <span className="inline-block text-tesla-red">worldwide.</span>
      </Heading>

      <div className="grid mt-12 gap-x-6 gap-y-6 lg:mt-16 lg:grid-cols-3">
        <div className="flex flex-col space-y-6">
          {configFirst.map(review => (
            <Review
              key={review.name}
              name={review.name}
              date={review.date}
              avatar={review.avatar}
              text={review.text}
              className={
                configFirst.indexOf(review) === configFirst.length - 1 &&
                'h-full'
              }
            />
          ))}
        </div>

        <div className="flex flex-col space-y-6">
          {configSecond.map(review => (
            <Review
              key={review.name}
              name={review.name}
              date={review.date}
              avatar={review.avatar}
              text={review.text}
              className={
                configSecond.indexOf(review) === configSecond.length - 1 &&
                'h-full'
              }
            />
          ))}
        </div>

        <div className="flex flex-col space-y-6">
          {configThird.map(review => (
            <Review
              key={review.name}
              name={review.name}
              date={review.date}
              avatar={review.avatar}
              text={review.text}
              className={
                configThird.indexOf(review) === configThird.length - 1 &&
                'h-full'
              }
            />
          ))}
        </div>
      </div>
    </>
  )
}
